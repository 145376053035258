import { UnitType } from "@/modules/warehouses/inventories/types"
import { Http } from "@/network/http"
import { Filters } from "@/utils/constants"

export const API_PREFIX_PATH = "/units"

export const fetchUnits = async (filters?: Filters) => {
  return await Http.get(`${API_PREFIX_PATH}`, { params: filters })
}

export const fetchUnit = async (id: string) => {
  return await Http.get(`${API_PREFIX_PATH}/${id}`)
}

export const createUnit = async (data?: UnitType) => {
  return await Http.post(`${API_PREFIX_PATH}`, data)
}
