export interface InventoryType {
  id?: string
  name: string
  status: string
  quantity: number
  created_at: string
  updated_at: string
  unit_id: number
  unit: UnitType
}

export interface UnitType {
  id?: string
  name: string
  unit_group: string
}

export enum UnitGroupEnum {
  QUANTITY = "quantity",
  WEIGHT = "weight",
  VOLUME = "volume",
}

export const Mapped_unitsGroup = {
  [UnitGroupEnum.QUANTITY]: "Số lượng",
  [UnitGroupEnum.WEIGHT]: "Khối lượng",
  [UnitGroupEnum.VOLUME]: "Thể tích",
}
