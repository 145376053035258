import { TypeReceipts } from "@/modules/finances/receipts/types"
import { ApexOptions } from "apexcharts"

const getNearest12Months = () => {
  const res: string[] = []
  const date = new Date()
  date.setDate(1)
  res.unshift(
    `${date.getMonth() + 1 >= 10 ? `${date.getMonth() + 1}` : `0${date.getMonth() + 1}`}/${date.getFullYear()}`,
  )
  for (let i = 0; i < 11; i++) {
    date.setMonth(date.getMonth() - 1)
    res.unshift(
      `${date.getMonth() + 1 >= 10 ? `${date.getMonth() + 1}` : `0${date.getMonth() + 1}`}/${date.getFullYear()}`,
    )
  }
  return res
}

export const getAllDaysInYear = () => {
  const res: string[] = []
  const date = new Date(new Date().getFullYear(), 0, 1)
  while (date.getFullYear() === new Date().getFullYear()) {
    res.push(
      `${date.getDate() >= 10 ? `${date.getDate()}` : `0${date.getDate()}`}/${
        date.getMonth() + 1 >= 10 ? `${date.getMonth() + 1}` : `0${date.getMonth() + 1}`
      }`,
    )
    date.setDate(date.getDate() + 1)
  }
  return res
}

export enum TitleChart {
  staffs = "staffs",
  receipts = "receipts",
  debts = "debts",
  receipt_categories_income = "receipt_categories_income",
  receipt_categories_expense = "receipt_categories_expense",
  receipt_categories_expense_by_day = "receipt_categories_expense_by_day",
  receipt_categories_income_by_day = "receipt_categories_income_by_day",
}
export const MAP_TitleChart = {
  [TitleChart.staffs]: "Nhân viên",
  [TitleChart.receipts]: "Thu chi",
  [TitleChart.debts]: "Công nợ",
  [TitleChart.receipt_categories_income]: "Thu theo danh mục theo tháng",
  [TitleChart.receipt_categories_expense]: "Chi theo danh mục theo tháng",
  [TitleChart.receipt_categories_income_by_day]: "Thu theo danh mục theo ngày",
  [TitleChart.receipt_categories_expense_by_day]: "Chi theo danh mục theo ngày",
}

export const lineChartOption: ApexOptions = {
  chart: {
    width: "100%",
    height: 350,
    type: "area",
    toolbar: {
      show: true,
      tools: {
        download: true,
        selection: true,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true,
      },
    },
    zoom: {
      enabled: true,
    },
  },
  legend: {
    show: true,
    position: "bottom",
    horizontalAlign: "center",
    floating: false,
    markers: {
      width: 12,
      height: 12,
      radius: 12,
    },
    itemMargin: {
      horizontal: 10,
      vertical: 5,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  markers: {
    size: 0,
  },
  yaxis: {
    labels: {
      style: {
        fontSize: "14px",
        fontWeight: 600,
        colors: ["#8c8c8c"],
      },
      formatter: function (val: any) {
        if (val !== undefined && val !== null) {
          return val.toLocaleString("it-IT", { style: "currency", currency: "VND" })
        }
        return "N/A"
      },
    },
  },
  xaxis: {
    labels: {
      style: {
        fontSize: "14px",
        fontWeight: 600,
        colors: "#8c8c8c",
      },
    },
    categories: getNearest12Months(),
  },
  tooltip: {
    shared: true,
    intersect: false,
    y: {
      formatter: function (val: any) {
        if (val !== undefined && val !== null) {
          return val.toLocaleString("it-IT", { style: "currency", currency: "VND" })
        }
        return "N/A"
      },
    },
  },
}

export const lineChartOptionByDay: ApexOptions = {
  chart: {
    width: "100%",
    height: 350,
    type: "area",
    toolbar: {
      show: true,
      tools: {
        download: true,
        selection: true,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true,
      },
    },
    zoom: {
      enabled: true,
    },
  },
  legend: {
    show: true,
    position: "bottom",
    horizontalAlign: "center",
    floating: false,
    markers: {
      width: 12,
      height: 12,
      radius: 12,
    },
    itemMargin: {
      horizontal: 10,
      vertical: 5,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  markers: {
    size: 0,
  },
  yaxis: {
    labels: {
      style: {
        fontSize: "14px",
        fontWeight: 600,
        colors: ["#8c8c8c"],
      },
      formatter: function (val: any) {
        if (val !== undefined && val !== null) {
          return val.toLocaleString("it-IT", { style: "currency", currency: "VND" })
        }
        return "N/A"
      },
    },
  },
  xaxis: {
    labels: {
      style: {
        fontSize: "14px",
        fontWeight: 600,
        colors: "#8c8c8c",
      },
    },
    categories: getAllDaysInYear(),
  },
  tooltip: {
    shared: true,
    intersect: false,
    y: {
      formatter: function (val: any) {
        if (val !== undefined && val !== null) {
          return val.toLocaleString("it-IT", { style: "currency", currency: "VND" })
        }
        return "N/A"
      },
    },
  },
}

export interface StatisticData {
  income_expense: IncomeExpenseStatistic
  staffs: StaffStatisticType
  payable_debts: PayableDebts
  receivable_debts: ReceivableDebts
  receipt_categories: ChartReceiptCategory
  receipt_categories_by_day: ChartReceiptCategoryByDay
}

export interface ChartReceiptCategory {
  income: IncomeReceiptCategory[]
  expense: ExpenseReceiptCategory[]
}

export interface IncomeReceiptCategory {
  type: string
  category_name: string
  month: string
  value: number
}

export interface ExpenseReceiptCategory {
  type: string
  category_name: string
  month: string
  value: number
}

export interface ChartReceiptCategoryByDay {
  income: IncomeReceiptCategoryDay[]
  expense: ExpenseReceiptCategoryDay[]
}

export interface IncomeReceiptCategoryDay {
  type: string
  category_name: string
  day: string
  value: number
}

export interface ExpenseReceiptCategoryDay {
  type: string
  category_name: string
  day: string
  value: number
}

export interface IncomeExpenseStatistic {
  income: IncomeExpense[]
  expense: IncomeExpense[]
  chart: Chart
}

export interface IncomeExpense {
  name: string
  value: number
}

export interface Chart {
  income: ChartType[]
  expense: ChartType[]
}

export interface ChartType {
  receipts_type: string
  month: string
  value: number
}

export interface StaffStatisticType {
  count: number
  basic_income: number
  final_income: number
  chart: ChartStaff[]
}

export interface ChartStaff {
  month: string
  income: number
}

export type PayableDebts = {
  total_payable_debts: number
  chart: DebtsChart[]
}

export interface DebtsChart {
  month: string
  amount: number
}

export type ReceivableDebts = {
  total_receivable_debts: number
  chart: DebtsChart[]
}

export interface DataStaticType {
  name: string
  data: number[]
  dataDetail?: any[]
  offsetY: number
  color: string
  total?: number
  suffix: string
  hidden?: boolean
}

export interface StatisticCard {
  name: string
  value: number
  prefix?: string
  suffix?: string
}

export const STATISTIC_DATES = {
  today: "hôm nay",
  thisWeek: "tuần này",
  thisMonth: "tháng này",
  lastWeek: "tuần trước",
}

export const STATISTIC_RECEIPTS = {
  [TypeReceipts.EXPENSE]: "Chi tiêu",
  [TypeReceipts.INCOME]: "Doanh thu",
}
