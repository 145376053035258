import { InventoryType } from "@/modules/warehouses/inventories/types"
import { Http } from "@/network/http"
import { notificationAction } from "@/utils"
import { Filters, NotificationEnum, StatusEnum } from "@/utils/constants"

export const API_PREFIX_PATH = "/inventories"

export const fetchInventories = async (filters?: Filters) => {
  return await Http.get(`${API_PREFIX_PATH}`, { params: filters })
}

export const fetchInventory = async (id: string) => {
  return await Http.get(`${API_PREFIX_PATH}/${id}`)
}

export const createInventory = async (data?: InventoryType) => {
  return await Http.post(`${API_PREFIX_PATH}`, data)
}

export const updateInventory = async (id: string, data?: InventoryType) => {
  return await Http.put(`${API_PREFIX_PATH}/${id}`, data)
}

export const deleteInventory = async (data: InventoryType) => {
  try {
    const updatedData = { ...data }
    if (!updatedData.id) return
    const response = await updateInventory(updatedData.id, { ...updatedData, status: StatusEnum.DELETED })
    if (response.statusCode === 200) {
      notificationAction(NotificationEnum.DELETED, NotificationEnum.SUCCESS)
    }
  } catch (error) {
    console.error("Addition failed:", error)
    notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
  }
}
