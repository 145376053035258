import { Paths } from "@/constants"
import UpsertForm from "@/modules/warehouses/inventory-transactions/view/UpsertForm"
import { useGoBack } from "@/utils/use-go-back"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

export const Upsert = () => {
  const { id } = useParams()

  const [isUpdate, setUpdate] = useState<boolean>(false)

  useEffect(() => {
    async function init() {
      if (id) {
        setUpdate(true)
      }
    }

    init()
  }, [])
  const { goBack } = useGoBack(`${Paths.INVENTORYTRANSACTIONS.LIST.PATH}`)

  const onCancel = () => goBack()
  const onRefreshProps = () => true
  return (
    <>
      <UpsertForm
        key="UPDATE"
        cancelProps={onCancel}
        openProps={isUpdate}
        refreshProps={onRefreshProps}
        actionType="UPDATE"
        idEdit={id}
      />
    </>
  )
}
