import { createReceipt, fetchReceipt, updateReceipt } from "@/modules/finances/receipts/services/receipts.services"
import { Receipt, TypeReceipts } from "@/modules/finances/receipts/types"
import { ReceiptCategory } from "@/modules/settings/receipt-categories"
import { fetchReceiptCategories } from "@/modules/settings/receipt-categories/services/receipt-categories.serivces"
import {
  dateFormatList,
  filterOption,
  formatterInputNumber,
  notificationAction,
  notificationCreateSuccess,
  notificationUpdateSuccess,
} from "@/utils"
import { NotificationEnum, StatusEnum } from "@/utils/constants"
import { Button, DatePicker, Form, Input, InputNumber, Modal, Select, Space } from "antd"
import TextArea from "antd/es/input/TextArea"
import dayjs from "dayjs"
import { useEffect, useState } from "react"

interface UpsertFormProps {
  openProps: boolean
  cancelProps: () => void
  refreshProps: () => void
  actionType?: string
  idEdit?: string
}
const UpsertForm: React.FC<UpsertFormProps> = props => {
  const [form] = Form.useForm()
  const [receiptCategories, setReceiptCategories] = useState<ReceiptCategory[]>([])
  const [typeReceipt, setTypeReceipt] = useState("expense")
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)

  const onReset = () => {
    form.resetFields()
  }

  useEffect(() => {
    if (props.actionType === "CREATE") {
      form.resetFields()
    }
  }, [props.actionType])

  const handleCompleteForm = () => {
    onReset()
    props.refreshProps()
    props.cancelProps()
    props.idEdit ? notificationUpdateSuccess() : notificationCreateSuccess()
    setButtonLoading(false)
  }

  const getReceiptCategories = async () => {
    try {
      let obj = {}
      if (typeReceipt !== "") {
        obj = { ...obj, type: typeReceipt }
      }
      const response = await fetchReceiptCategories(obj)
      if (response?.data) {
        setReceiptCategories(response.data.items)
      }
    } catch (error) {
      console.error("Error fetching positions:", error)
    }
  }

  const handleAddReceipt = async () => {
    try {
      const values = await form.validateFields()
      const newData: Receipt = {
        ...values,
        status: StatusEnum.ACTIVE,
      }
      setButtonLoading(true)
      const response = await createReceipt(newData)
      if (response.statusCode === 200) {
        handleCompleteForm()
      }
    } catch (error) {
      setButtonLoading(false)
      console.error("Addition failed:", error)
      notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
    }
  }

  useEffect(() => {
    const onInitEdit = async (idEdit: string) => {
      const receipt = await fetchReceipt(idEdit)
      setTypeReceipt(receipt.data.type)

      form.setFieldsValue({
        name: receipt.data.name,
        amount: receipt.data.amount,
        category_id: receipt.data.category_id,
        type: receipt.data.type,
        notes: receipt.data.notes,
        date_happened: dayjs(receipt.data.date_happened),
      })
    }

    if (props.idEdit) {
      onInitEdit(props.idEdit)
    }
  }, [props.idEdit])

  const handleUpdateReceipt = async () => {
    try {
      const values = await form.validateFields()
      if (props.idEdit) {
        const updateData = { ...values, status: StatusEnum.ACTIVE }
        setButtonLoading(true)
        const response = await updateReceipt(props.idEdit, updateData)
        if (response.statusCode === 200) {
          handleCompleteForm()
        }
      }
    } catch (error) {
      setButtonLoading(false)
      console.error("Edit failed:", error)
      notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
    }
  }

  useEffect(() => {
    getReceiptCategories()
    form.setFieldValue("category_id", null)
  }, [typeReceipt])

  const renderFooter = () => {
    return (
      <>
        <Space>
          <Button
            onClick={() => {
              onReset()
            }}
          >
            Làm mới
          </Button>
          <Button
            key="cancel"
            onClick={() => {
              setTypeReceipt("expense")
              props.cancelProps()
              onReset()
            }}
          >
            Hủy
          </Button>
          {props.actionType === "UPDATE" && (
            <Button type="primary" onClick={handleUpdateReceipt} loading={buttonLoading}>
              Lưu
            </Button>
          )}
          {props.actionType === "CREATE" && (
            <Button type="primary" onClick={() => handleAddReceipt()} loading={buttonLoading}>
              Thêm mới
            </Button>
          )}
        </Space>
      </>
    )
  }

  return (
    <Modal
      title={props.actionType === "CREATE" ? "Thêm hóa đơn" : "Chỉnh sửa hóa đơn"}
      open={props.openProps}
      width={600}
      onCancel={() => {
        setTypeReceipt("expense")
        props.cancelProps()
      }}
      footer={renderFooter()}
    >
      <Form
        name="wrap"
        labelCol={{ flex: "120px" }}
        labelAlign="left"
        labelWrap
        wrapperCol={{ flex: 1 }}
        colon={false}
        form={form}
      >
        <Form.Item label="Tên hóa đơn:" name="name" rules={[{ required: true, message: "Vui lòng nhập dữ liệu vào!" }]}>
          <Input placeholder="Nhập tên hóa đơn" />
        </Form.Item>
        <Form.Item
          label="Tiền:"
          name="amount"
          rules={[{ required: true, pattern: new RegExp(/^[0-9]+$/), message: "Vui lòng nhập dữ liệu số!" }]}
        >
          <InputNumber
            style={{ width: "100%" }}
            placeholder="Nhập tiền hóa đơn"
            formatter={value => formatterInputNumber(value)}
            addonAfter="VNĐ"
            step={10000}
            min={0}
          />
        </Form.Item>
        <Form.Item
          name="date_happened"
          label="Ngày diễn ra"
          rules={[{ required: true, message: "Vui lòng chọn ngày diễn ra!" }]}
          initialValue={dayjs(new Date())}
        >
          <DatePicker placeholder="Chọn thời gian" format={dateFormatList} />
        </Form.Item>
        <Form.Item label="Ghi chú:" name="notes">
          <TextArea placeholder="Ghi chú cho hóa đơn" />
        </Form.Item>
        <Form.Item label="Loại" name="type" initialValue={TypeReceipts.EXPENSE}>
          <Select
            placeholder="Vui lòng chọn loại"
            options={[
              { value: TypeReceipts.INCOME, label: "Thu" },
              { value: TypeReceipts.EXPENSE, label: "Chi" },
            ]}
            onChange={e => setTypeReceipt(e)}
            defaultValue={TypeReceipts.EXPENSE}
          />
        </Form.Item>
        <Form.Item
          name="category_id"
          label="Danh mục"
          rules={[{ required: true, message: "Vui lòng chọn loại danh mục!" }]}
        >
          <Select
            showSearch
            placeholder="Vui lòng chọn loại danh mục"
            filterOption={filterOption}
            optionFilterProp="children"
            options={receiptCategories.map(item => ({
              value: item.id as string,
              label: item.category,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default UpsertForm

const styles = {
  inputNumber: {
    width: "350px",
  },
}
