export interface LogType {
  id?: string
  action: string
  type: string
  user_id: number
  change_detail: string
  date: string
}

export enum LogActions {
  CREATE = "create",
  READ = "read",
  UPDATE = "update",
  DELETE = "delete",
  RESTORE = "restore",
  CREATEMASS = "createMass",
}

export const Mapped_LogActions = {
  [LogActions.CREATE]: "Tạo",
  [LogActions.UPDATE]: "Sửa",
  [LogActions.DELETE]: "Xóa",
  [LogActions.RESTORE]: "Khôi phục",
  [LogActions.READ]: "Xem",
  [LogActions.CREATEMASS]: "Tạo nhiều checkIns",
}
