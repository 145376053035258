import { Paths, PERMISSIONS } from "@/constants"

import type { ItemType } from "antd/lib/menu/hooks/useItems"

export type MenuItem = ItemType & {
  path?: string
  permissionkey: string
  parent?: string
}

export const commonItems: MenuItem[] = [
  {
    key: Paths.USER.LIST.PATH,
    label: Paths.USER.LIST.LABEL,
    permissionkey: PERMISSIONS.USER_GET_MANY,
  },
  {
    key: Paths.ROLE.LIST.PATH,
    label: Paths.ROLE.LIST.LABEL,
    permissionkey: PERMISSIONS.ROLE_GET_MANY,
  },
  {
    key: Paths.PERMISSION.LIST.PATH,
    label: Paths.PERMISSION.LIST.LABEL,
    permissionkey: PERMISSIONS.PERMISSION_GET_MANY,
  },
]

export const hrItems: MenuItem[] = [
  {
    key: Paths.STAFF.LIST.PATH,
    label: Paths.STAFF.LIST.LABEL,
    permissionkey: PERMISSIONS.USER_GET_MANY,
  },
  {
    key: Paths.CHECKIN.LIST.PATH,
    label: Paths.CHECKIN.LIST.LABEL,
    permissionkey: PERMISSIONS.USER_GET_MANY,
  },
  {
    key: Paths.PAYSHEET.LIST.PATH,
    label: Paths.PAYSHEET.LIST.LABEL,
    permissionkey: PERMISSIONS.USER_GET_MANY,
  },
  {
    key: Paths.INTERNALDEBTS.LIST.PATH,
    label: Paths.INTERNALDEBTS.LIST.LABEL,
    permissionkey: PERMISSIONS.USER_GET_MANY,
  },
]

export const settingItems: MenuItem[] = [
  {
    key: Paths.CATEGORY.LIST.PATH,
    label: Paths.CATEGORY.LIST.LABEL,
    permissionkey: PERMISSIONS.USER_GET_MANY,
  },
  {
    key: Paths.WORKDAYS.LIST.PATH,
    label: Paths.WORKDAYS.LIST.LABEL,
    permissionkey: PERMISSIONS.USER_GET_MANY,
  },
]

export const financeItems: MenuItem[] = [
  {
    key: Paths.RECEIPT.LIST.PATH,
    label: Paths.RECEIPT.LIST.LABEL,
    permissionkey: PERMISSIONS.USER_GET_MANY,
  },
  {
    key: Paths.PAYABLEDEBTS.LIST.PATH,
    label: Paths.PAYABLEDEBTS.LIST.LABEL,
    permissionkey: PERMISSIONS.USER_GET_MANY,
  },
  {
    key: Paths.RECEIVABLEDEBTS.LIST.PATH,
    label: Paths.RECEIVABLEDEBTS.LIST.LABEL,
    permissionkey: PERMISSIONS.USER_GET_MANY,
  },
  {
    key: Paths.CURRENCY.LIST.PATH,
    label: Paths.CURRENCY.LIST.LABEL,
    permissionkey: PERMISSIONS.USER_GET_MANY,
  },
  {
    key: Paths.PROPERTY.LIST.PATH,
    label: Paths.PROPERTY.LIST.LABEL,
    permissionkey: PERMISSIONS.USER_GET_MANY,
  },
]

export const warehouseItems: MenuItem[] = [
  {
    key: Paths.INVENTORY.LIST.PATH,
    label: Paths.INVENTORY.LIST.LABEL,
    permissionkey: PERMISSIONS.USER_GET_MANY,
  },
  {
    key: Paths.INVENTORYTRANSACTIONS.LIST.PATH,
    label: Paths.INVENTORYTRANSACTIONS.LIST.LABEL,
    permissionkey: PERMISSIONS.USER_GET_MANY,
  },
]

export const menuItems: Array<ItemType & { parent?: string }> = [
  { label: "Dashboard", key: "/" },
  { label: "Quản lí hệ thống", key: "manages", children: commonItems },
  { label: "Cài đặt", key: "settings", children: settingItems },
  { label: "Nhân sự", key: "hrs", children: hrItems },
  { label: "Tài chính", key: "finances", children: financeItems },
  { label: "Quản lí kho", key: "warehouses", children: warehouseItems },
  { label: "Đối tác", key: "partners" },
  { label: "Khách hàng", key: "customers" },
  { label: "Logs", key: "logs" },
  { label: "Báo cáo Kinh Doanh", key: "finance-report" },
]
