import { useAuthContext } from "@/components/auth"
import { menuItems } from "@/components/layout/menuItems"
import { RootState } from "@/redux/store"
import { Button, Drawer, Layout, Menu, Modal, Space } from "antd"
import { split, trimStart } from "lodash"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"

const renderModalFooter = (onOk: () => void, onCancel: () => void) => {
  return (
    <>
      <Space>
        <Button
          type="primary"
          danger
          onClick={() => {
            onOk()
          }}
        >
          Đăng xuất
        </Button>
        <Button
          key="cancel"
          onClick={() => {
            onCancel()
          }}
        >
          Hủy
        </Button>
      </Space>
    </>
  )
}

export const Header = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const auth = useAuthContext()
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false)
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768)
  const parts = split(trimStart(location.pathname, "/"), "/")
  const profile: any = useSelector<RootState>((state: RootState) => state.profile)

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768)
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <Layout.Header
      className="layout"
      style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
    >
      <Modal
        title="Bạn chắc chắn muốn đăng xuất?"
        open={isOpenModal}
        width={600}
        onCancel={() => setIsOpenModal(false)}
        footer={renderModalFooter(
          () => auth.logout(),
          () => setIsOpenModal(false),
        )}
      />
      <a className="logo" onClick={() => navigate("/")} href="/">
        <img src="/images/logo.png" alt="Logo" />
      </a>
      <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        {isMobile ? (
          <>
            <Button
              type="default"
              onClick={() => setIsDrawerVisible(true)}
              style={{
                fontSize: "1.5rem",
                padding: "0 0.5rem",
                border: "none",
                background: "none",
                color: "black",
                cursor: "pointer",
              }}
            >
              ☰
            </Button>
            <Drawer title="Menu" placement="right" onClose={() => setIsDrawerVisible(false)} open={isDrawerVisible}>
              <Menu
                mode={isMobile ? "inline" : "horizontal"}
                // mode="vertical"
                items={menuItems}
                onClick={info => {
                  navigate(info.key)
                  setIsDrawerVisible(false)
                }}
                defaultSelectedKeys={[parts[0] ? parts[0] : "/"]}
              />
            </Drawer>
          </>
        ) : (
          <Menu
            mode="horizontal"
            items={menuItems}
            onClick={info => navigate(info.key)}
            defaultSelectedKeys={[parts[0] ? parts[0] : "/"]}
            onSelect={info => navigate(info.key)}
            style={{
              flex: "auto",
              display: "flex",
              justifyContent: "center",
              background: "transparent",
              borderBottom: "none",
            }}
          />
        )}
        {/* <Menu
          mode="horizontal"
          items={menuItems}
          onClick={info => {
            navigate(info.key)
          }}
          defaultSelectedKeys={[parts[0] ? parts[0] : "/"]}
          onSelect={info => {
            navigate(info.key)
          }}
          style={{ minWidth: 0, flex: "auto" }}
        /> */}
        <div style={{ display: "flex", alignItems: "center", gap: "0.5rem", width: "auto" }}>
          <p>Hi, {profile.user.user_fullname}</p>
          <Button type="primary" danger onClick={() => setIsOpenModal(true)}>
            Đăng xuất
          </Button>
        </div>
      </div>

      {/* <Dropdown overlay={rightMenu}>
        <a onClick={e => e.preventDefault()}>Hi, {profile.data.username}</a>
      </Dropdown> */}
    </Layout.Header>
  )
}
