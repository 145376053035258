import Filters, { FilterComponent } from "@/components/Filters"
import {
  deleteReceivableDebt,
  fetchReceivableDebts,
} from "@/modules/finances/receivable_debts/services/receivabledebts.services"
import { ReceivableDebt } from "@/modules/finances/receivable_debts/types"
import UpsertForm from "@/modules/finances/receivable_debts/view/UpsertForm"
import { ResponseData } from "@/types"
import { formatMoney, normalizeText } from "@/utils"
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons"
import { Button, Card, Flex, Popconfirm, Space, Table, Tag, Typography } from "antd"
import { SorterResult } from "antd/es/table/interface"
import dayjs from "dayjs"
import queryString from "query-string"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { AnyObject } from "yup/lib/types"

type EditableTableProps = Parameters<typeof Table>[0]

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>

const List: React.FC = () => {
  const [dataSource, setDataSource] = useState<ResponseData<ReceivableDebt>>()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [needRefresh, setNeedRefresh] = useState(false)
  const [idEdit, setIdEdit] = useState<string>("")
  const [actionType, setActionType] = useState<string>("")
  const [currentPage, setCurrentPage] = useState(1)
  const location = useLocation()
  const filterRef = useRef<{ filterFunction: () => void } | null>(null)
  const [defaultFilterValues, setDefaultFilterValues] = useState<{ [key: string]: any } | null>(null)
  const [filterValues, setFilterValues] = useState<{ [key: string]: any } | null>(null)
  const [sortPaginationValues, setSortPaginationValues] = useState<{ [key: string]: string } | null>(null)

  const navigate = useNavigate()

  const onRefresh = () => {
    setNeedRefresh(!needRefresh)
    if (filterRef.current) {
      filterRef.current.filterFunction()
    }
    setSortPaginationValues(null)
  }

  const onDismissModal = () => {
    setOpen(false)
  }

  const onTableChanged = ((pagination, _, sorter: SorterResult<AnyObject>) => {
    const sortOptions: { [key: string]: string } = sorter.order
      ? { [sorter.field as string]: sorter.order === "descend" ? "desc" : "asc" }
      : {}
    const paginationOptions: { [key: string]: string } = {
      page: `${pagination.current}`,
      limit: `${pagination.pageSize}`,
    }

    if (pagination && pagination.current) {
      setCurrentPage(pagination.current)
    }

    setSortPaginationValues({ ...sortOptions, ...paginationOptions })
    const searchParams = queryString.stringify({ ...filterValues, ...sortOptions, ...paginationOptions })

    navigate(searchParams.length > 0 ? `${location.pathname}?${searchParams}` : location.pathname)
  }) as EditableTableProps["onChange"]

  const showModal = () => {
    setActionType("CREATE")
    setOpen(true)
  }

  const showModalEdit = (id: string) => {
    navigate(`/finances/receivable-debts/${id}${location.search}`)
    setIdEdit(id)
    setOpen(true)
    setActionType("UPDATE")
  }

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: "5%",
    },
    {
      title: "Tên khách hàng",
      dataIndex: "borrower",
      key: "borrower",
      width: "20%",
      render: item => item.name,
    },
    {
      title: "Tiền",
      dataIndex: "amount",
      key: "amount",
      width: "15%",
      sorter: true,
      sortDirections: ["descend", "ascend", null],
      sortOrder: sortPaginationValues
        ? sortPaginationValues.amount
          ? sortPaginationValues.amount === "asc"
            ? "ascend"
            : "descend"
          : null
        : null,
      render: items => `${formatMoney(items)} đ`,
    },
    {
      title: "Ghi chú",
      dataIndex: "note",
      key: "note",
      width: "25%",
    },
    {
      title: "Trạng thái",
      dataIndex: "settlement",
      key: "settlement",
      width: "15%",
      render: settlement => {
        return <Tag color={settlement ? "success" : "error"}>{settlement ? "Đã thanh toán" : "Chưa thanh toán"}</Tag>
      },
    },
    {
      title: "Ngày nợ",
      dataIndex: "date_happened",
      key: "date_happened",
      render: date_happened => date_happened && dayjs(new Date(date_happened)).format("DD/MM/YYYY"),
      width: "15%",
    },
    {
      title: "Ngày tới hạn",
      dataIndex: "due_date",
      key: "due_date",
      render: due_date => due_date && dayjs(new Date(due_date)).format("DD/MM/YYYY"),
      width: "15%",
    },
    {
      title: "Ngày tạo",
      dataIndex: "created_at",
      key: "created_at",
      render: created_at => created_at && dayjs(new Date(created_at)).format("HH:mm DD/MM/YYYY"),
      width: "15%",
    },
    {
      title: "Ngày cập nhật",
      dataIndex: "updated_at",
      key: "updated_at",
      render: updated_at => updated_at && dayjs(new Date(updated_at)).format("HH:mm DD/MM/YYYY"),
      width: "15%",
    },
    {
      title: "Thao tác",
      dataIndex: "operation",
      render: (_, record) =>
        dataSource && dataSource?.items.length >= 1 ? (
          <Space>
            <Button ghost type="primary" shape="round" size="small" onClick={() => showModalEdit(record?.id)}>
              Sửa
            </Button>
            <Popconfirm
              title="Bạn có chắc chắn muốn xóa?"
              onConfirm={() => handleDeleteReceivableDebt(record as ReceivableDebt)}
            >
              <Button type="default" shape="round" danger size="small">
                Xóa
              </Button>
            </Popconfirm>
          </Space>
        ) : null,
      width: "15%",
    },
  ]

  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: (record: ReceivableDebt) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    }
  })

  const onSearch = useCallback(
    (values: any) => {
      let newValue = { ...values, ...sortPaginationValues }

      if (values.search) {
        newValue = { ...newValue, search: normalizeText(values.search) }
      }

      if (newValue.min_date_happened) {
        newValue = { ...newValue, min_date_happened: dayjs(newValue.min_date_happened).format("YYYY-MM-DD") }
      }

      if (newValue.max_date_happened) {
        newValue = { ...newValue, max_date_happened: dayjs(newValue.max_date_happened).format("YYYY-MM-DD") }
      }

      const searchParams = queryString.stringify(newValue)
      const newUrl = searchParams.length > 0 ? `${location.pathname}?${searchParams}` : location.pathname
      navigate(newUrl)
    },
    [sortPaginationValues],
  )

  useEffect(() => {
    if (!defaultFilterValues && !location.search) return
    const searchParams = queryString.parse(location.search)
    const filterValues: { [key: string]: any } = {}
    const sortPaginationValues: { [key: string]: string } = {}
    for (const key of Object.keys(searchParams)) {
      const value = searchParams[key]
      if (value && typeof value === "string") {
        if (key === "page" || key === "limit" || value === "asc" || value === "desc") {
          sortPaginationValues[key] = value
        } else {
          filterValues[key] = value
        }
      }
    }

    setFilterValues(filterValues)
    setSortPaginationValues(sortPaginationValues)
  }, [location.search])

  useEffect(() => {
    if (!filterValues && !sortPaginationValues) return
    const params = { ...filterValues, ...sortPaginationValues }
    setLoading(true)
    fetchReceivableDebts(params).then((res: any) => {
      if (sortPaginationValues && sortPaginationValues.page) {
        if (sortPaginationValues.page > res.data.meta.totalPages && res.data.meta.totalPages > 0) {
          const searchLocation = queryString.parse(location.search)
          const newParams = queryString.stringify({ ...searchLocation, page: `${res.data.meta.totalPages}` })
          const newUrl = newParams.length > 0 ? `${location.pathname}?${newParams}` : location.pathname
          navigate(newUrl)
        }
      }
      setDataSource(res.data)
      setLoading(false)
    })
  }, [filterValues, sortPaginationValues, needRefresh])

  useEffect(() => {
    const searchParams = queryString.parse(location.search)
    const filterValues: { [key: string]: any } = {}
    const sortPaginationValues: { [key: string]: string } = {}
    for (const key of Object.keys(searchParams)) {
      const value = searchParams[key]
      if (value && typeof value === "string") {
        if (value === "true" || value === "false") {
          filterValues[key] = value === "true" ? true : false
          continue
        }
        if (key === "min_date_happened" || key === "max_date_happened") {
          filterValues[key] = dayjs(value)
          continue
        }
        if (key === "page" || key === "limit" || value === "asc" || value === "desc") {
          if (key === "page") {
            setCurrentPage(parseInt(value))
          }
          sortPaginationValues[key] = value
        } else {
          filterValues[key] = value
        }
      }
    }

    setDefaultFilterValues({ settlement: false, ...filterValues, ...sortPaginationValues })
  }, [])

  const handleDeleteReceivableDebt = async (record: ReceivableDebt) => {
    await deleteReceivableDebt(record)
    setNeedRefresh(!needRefresh)
  }

  return (
    <div>
      <Card>
        <Flex justify="space-between" style={{ flexWrap: "wrap" }}>
          <Typography.Title level={4} style={styles.title}>
            Danh sách công nợ phải thu
          </Typography.Title>
          <Space style={{ flexWrap: "wrap", marginTop: "10px" }}>
            <Button onClick={onRefresh} icon={<ReloadOutlined />}>
              Làm mới
            </Button>
            <Button type="primary" onClick={() => showModal()} icon={<PlusOutlined />}>
              Thêm mới
            </Button>
          </Space>
        </Flex>

        <Card type="inner" style={styles.card}>
          <Filters
            filterRef={filterRef}
            defaultFilterValues={defaultFilterValues}
            onSubmit={onSearch}
            filters={
              [
                {
                  label: "Tìm tên khách hàng",
                  placeholder: "Tên khách hàng",
                  name: "search",
                  type: "text",
                  defaultValue: "",
                },
                {
                  label: "Lọc theo ngày nợ từ",
                  placeholder: "Vui lòng chọn ngày từ",
                  name: "min_date_happened",
                  type: "date",
                  defaultValue: "",
                },
                {
                  label: "đến",
                  placeholder: "Vui lòng chọn",
                  name: "max_date_happened",
                  type: "date",
                  defaultValue: "",
                },
                {
                  label: "Lọc theo trạng thái",
                  placeholder: "Vui lòng chọn trạng thái",
                  name: "settlement",
                  type: "select",
                  options: [
                    { value: "", label: "Tất cả" },
                    { value: true, label: "Đã thanh toán" },
                    { value: false, label: "Chưa thanh toán" },
                  ],
                  defaultValue: false,
                },
              ] as FilterComponent[]
            }
          />
        </Card>

        {dataSource && dataSource.totalDebt && dataSource.totalDebt.total_amount && (
          <Flex align="center" gap={16} style={{ marginBottom: "1.25rem" }}>
            <Typography.Text style={{ fontSize: "1rem" }}>
              <Typography.Text>Tổng công nợ chưa thu: </Typography.Text>
              <Typography.Text strong>{formatMoney(dataSource.totalDebt.total_amount)} đ</Typography.Text>
            </Typography.Text>
          </Flex>
        )}
        <UpsertForm
          openProps={open}
          cancelProps={onDismissModal}
          refreshProps={onRefresh}
          actionType={actionType}
          idEdit={idEdit}
        />

        <Table
          rowClassName={() => "editable-row"}
          bordered
          dataSource={dataSource?.items}
          columns={columns as ColumnTypes}
          loading={loading}
          scroll={{ x: 1200, y: 400 }}
          onChange={onTableChanged}
          pagination={{
            total: dataSource?.meta.totalItems,
            pageSize: dataSource?.meta.itemsPerPage,
            current: currentPage,
            responsive: true,
          }}
          rowKey={record => record.id}
        />
      </Card>
    </div>
  )
}

export default List

const styles = {
  title: {
    marginTop: 0,
    marginBottom: 0,
  },
  card: {
    marginTop: "1.25rem",
    marginBottom: "1.25rem",
  },
}
