import {
  createAsset,
  createProperty,
  fetchAssets,
  fetchProperty,
  updateProperty,
} from "@/modules/finances/properties/services/properties.services"
import { AssetName, PropertyType } from "@/modules/finances/properties/types"
import { Staff } from "@/modules/hrs/staffs"
import { fetchStaffs } from "@/modules/hrs/staffs/services/staff.services"
import { normalizeText, notificationAction } from "@/utils"
import { NotificationEnum, StatusEnum } from "@/utils/constants"
import { PlusOutlined } from "@ant-design/icons"
import { Button, Divider, Form, Input, Modal, Select, Space } from "antd"
import { InputNumber } from "antd/lib"
import { useEffect, useState } from "react"

interface BaseUpsertForm {
  openProps?: boolean
  cancelProps: () => void
  refreshProps: () => void
  actionType?: string
  idEdit?: string
}

const UpsertForm: React.FC<BaseUpsertForm> = props => {
  const [loading, setLoading] = useState(false)
  const [formProperty] = Form.useForm()
  const [formAsset] = Form.useForm()
  const [isOpenModelAdd, setIsOpenModelAdd] = useState<boolean>(false)
  const [dataStaffs, setDataStaffs] = useState<Staff[]>()
  const [dataAsset, setDataAsset] = useState<AssetName[]>()
  const [propertyData, setPropertyData] = useState<PropertyType | object>({})

  const getStaffs = async () => {
    try {
      const response = await fetchStaffs()
      if (response?.data) {
        setDataStaffs(response.data.items)
      }
    } catch (error) {
      console.error("Error fetching positions:", error)
    }
  }

  const getAssetName = async () => {
    try {
      const response = await fetchAssets()
      if (response?.data) {
        setDataAsset(response.data)
      }
    } catch (error) {
      console.error("Error fetching positions:", error)
    }
  }

  useEffect(() => {
    getStaffs()
    getAssetName()
  }, [isOpenModelAdd])

  const onReset = () => {
    formProperty.resetFields()
    formAsset.resetFields()
  }

  const handleDismissModal = () => {
    if (isOpenModelAdd) {
      setIsOpenModelAdd(false)
    } else {
      props.cancelProps()
      onReset()
    }
  }

  const handleCompleteForm = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      onReset()
      props.refreshProps()
      handleDismissModal()
    }, 1000)
  }

  const handleAdd = async () => {
    try {
      if (isOpenModelAdd === false) {
        const values = await formProperty.validateFields()
        if (values.weight || values.quantity) {
          const newData: PropertyType = {
            status: StatusEnum.ACTIVE,
            ...values,
          }
          const response = await createProperty(newData)
          if (response.statusCode === 200) {
            handleCompleteForm()
            notificationAction(NotificationEnum.CREATED, NotificationEnum.SUCCESS)
          }
        } else notificationAction("Vui lòng nhập số lượng hoặc khối lượng! ", NotificationEnum.ERROR)
      } else {
        const values = await formAsset.validateFields()
        const newData: AssetName = {
          name: values.assetName.toString(),
        }
        const response = await createAsset(newData)
        if (response.statusCode === 200) {
          handleCompleteForm()
          notificationAction(NotificationEnum.CREATED, NotificationEnum.SUCCESS)
        }
      }
    } catch (error) {
      console.error("Addition failed:", error)
      notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
    }
  }

  useEffect(() => {
    if (props.actionType === "CREATE") {
      formProperty.resetFields()
    }

    if (props.actionType === "UPDATE" && props.idEdit) {
      fetchProperty(props.idEdit).then(res => {
        formProperty.setFieldsValue({
          asset_id: res.data.asset_id,
          quantity: res.data.quantity,
          weight: res.data.weight,
          staff_id: res.data.staff_id,
        })
        setPropertyData(res.data)
      })
    }
  }, [props.idEdit, props.actionType])

  const handleEdit = async () => {
    try {
      if (props.idEdit) {
        const values = await formProperty.validateFields()
        if (values.weight || values.quantity) {
          const response = await updateProperty(props.idEdit, {
            ...propertyData,
            ...values,
          })
          if (response.statusCode === 200) {
            handleCompleteForm()
            notificationAction(NotificationEnum.UPDATED, NotificationEnum.SUCCESS)
          }
        }
      }
      notificationAction("Vui lòng nhập số lượng hoặc khối lượng! ", NotificationEnum.ERROR)
    } catch (error) {
      console.error("Edit failed:", error)
      notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
    }
  }

  const renderFooter = () => {
    return (
      <>
        <Space>
          <Button
            onClick={() => {
              onReset()
            }}
          >
            Làm mới
          </Button>
          <Button key="cancel" onClick={handleDismissModal}>
            Hủy
          </Button>
          {props.actionType === "UPDATE" && (
            <Button type="primary" onClick={handleEdit} loading={loading}>
              Lưu
            </Button>
          )}
          {props.actionType === "CREATE" && (
            <Button type="primary" onClick={() => handleAdd()} loading={loading}>
              Thêm mới
            </Button>
          )}
        </Space>
      </>
    )
  }

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    normalizeText((option?.label ?? "").toLowerCase()).includes(normalizeText(input.toLowerCase()))

  return (
    <>
      <Modal
        title={props.actionType === "CREATE" ? "Thêm loại tài sản/hàng hoá" : "Quản lí loại tài sản/hàng hoá"}
        open={!isOpenModelAdd && props.openProps}
        width={600}
        onCancel={props.cancelProps}
        footer={renderFooter()}
      >
        <Form
          name="wrap"
          labelCol={{ flex: "140px" }}
          labelAlign="left"
          labelWrap
          wrapperCol={{ flex: 1 }}
          colon={false}
          style={{ maxWidth: 600 }}
          form={formProperty}
        >
          <Form.Item
            label="Tên loại tài sản/hàng hoá"
            name="asset_id"
            rules={[{ required: true, message: "Vui lòng nhập dữ liệu vào!" }]}
          >
            <Select
              showSearch
              placeholder="Chọn loại tài sản/hàng hoá"
              optionFilterProp="children"
              filterOption={filterOption}
              options={dataAsset?.map(item => ({
                value: item.id as string,
                label: item.name,
              }))}
              dropdownRender={menu => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsOpenModelAdd(true)}>
                      Thêm loại tài sản/hàng hoá mới
                    </Button>
                  </Space>
                </>
              )}
            />
          </Form.Item>
          <Form.Item label="Số lượng:" name="quantity">
            <InputNumber placeholder="Nhập số lượng" step={1} min={0} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item label="Khối lượng:" name="weight">
            <InputNumber addonAfter="KG" placeholder="Nhập khối lượng" step={0.1} min={0.1} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="Người kiểm kê:"
            name="staff_id"
            rules={[{ required: true, message: "Vui lòng chọn nhân viên!" }]}
          >
            <Select
              showSearch
              placeholder="Chọn nhân viên"
              optionFilterProp="children"
              filterOption={filterOption}
              options={dataStaffs?.map(item => ({
                value: item.id as string,
                label: item.name,
              }))}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={"Thêm tài sản/hàng hoá"}
        open={isOpenModelAdd}
        width={600}
        onCancel={handleDismissModal}
        footer={renderFooter()}
      >
        <Form
          name="wrap"
          labelCol={{ flex: "158px" }}
          labelAlign="left"
          labelWrap
          wrapperCol={{ flex: 1 }}
          colon={false}
          style={{ maxWidth: 600 }}
          form={formAsset}
        >
          <Form.Item
            label="Tên tài sản/hàng hoá"
            name="assetName"
            rules={[{ required: true, message: "Vui lòng nhập dữ liệu vào!" }]}
          >
            <Input placeholder="Nhập tài sản/hàng hoá" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
export default UpsertForm

const styles = {
  inputNumber: {
    width: "410px",
  },
}
