import { LogType } from "@/modules/log/types"
import { Http } from "@/network/http"
import { Filters } from "@/utils/constants"

export const API_PREFIX_PATH = "/logs"

export const fetchLogs = async (filters?: Filters) => {
  return await Http.get(`${API_PREFIX_PATH}`, { params: filters })
}

export const fetchLog = async (id: string) => {
  return await Http.get(`${API_PREFIX_PATH}/${id}`)
}

export const createLog = async (data?: LogType) => {
  return await Http.post(`${API_PREFIX_PATH}`, data)
}
