import { Breadcrumbs } from "@/components/Breadcrumbs"

import { Layout } from "antd"
import { FC } from "react"
import { Outlet } from "react-router-dom"
import { Header } from "./Header"

const { Content, Footer } = Layout

export const MainLayout: FC = () => {
  return (
    <Layout className="layout" id="mtb-erp-app">
      <Header />
      <Content style={styles.content}>
        <Breadcrumbs />
        <Layout>
          <Content>
            <Outlet />
          </Content>
        </Layout>
      </Content>
      {/* @ts-ignore */}
      <Footer style={styles.footer}>Copyright ©2024 - Dan Solutions Co., Ltd</Footer>
    </Layout>
  )
}

const styles = {
  content: { padding: "0 32px", minHeight: "calc(100vh - 134px)" },
  footer: { textAlign: "center" },
}
