import { User } from "@/modules/manages/users"
import { InventoryType } from "@/modules/warehouses/inventories"

export interface InventoryTransactionType {
  id?: string
  quantity: number
  notes: string
  transaction_type: string
  transaction_date: string
  created_at: string
  updated_at: string
  inventory_id: number
  status: string
  user_id: number
  user: User
  inventory: InventoryType
}

export interface UnitType {
  id?: string
  name: string
  unit_group: string
}

export enum UnitGroupEnum {
  QUANTITY = "quantity",
  WEIGHT = "weight",
  VOLUME = "volume",
}

export const Mapped_unitsGroup = {
  [UnitGroupEnum.QUANTITY]: "Số lượng",
  [UnitGroupEnum.WEIGHT]: "Khối lượng",
  [UnitGroupEnum.VOLUME]: "Thể tích",
}

export enum TransactionEnum {
  IN = "in",
  OUT = "out",
}

export const Mapped_transactionType = {
  [TransactionEnum.IN]: "Nhập kho",
  [TransactionEnum.OUT]: "Xuất kho",
}
