import { CustomersRouter } from "@/modules/customers"
import { DashBoardRouter } from "@/modules/dashboard"
import { FinanceReportRouter } from "@/modules/finance-report"
import { FinancesRouter } from "@/modules/finances/Router"
import { HrsRouter } from "@/modules/hrs/Router"
import { LogsRouter } from "@/modules/log"
import { PermissionRouter } from "@/modules/manages/permissions"
import { RolesRouter } from "@/modules/manages/roles"
import { UsersRouter } from "@/modules/manages/users"
import { PartnersRouter } from "@/modules/partners"
import { SettingsRouter } from "@/modules/settings/Router"
import { WareHousesRouter } from "@/modules/warehouses/Router"
import { Route, Routes } from "react-router-dom"
import { MainLayout } from "./components/layout"
import { Paths } from "./constants"
import { Login } from "./modules/core/pages/authentication/Login"
import history from "./navigation"
import { HistoryRouter } from "./navigation/HistoryRouter"
import { RequireAuth } from "./navigation/RequireAuth"

export const Navigator = () => {
  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route path={Paths.PUBLIC.LOGIN.PATH} element={<Login />} />
        <Route element={<RequireAuth />}>
          <Route element={<MainLayout />}>
            <Route path={Paths.DASHBOARD.DETAIL.PATH} element={<DashBoardRouter />} />
            <Route path={`${Paths.USER.LIST.PATH}/*`} element={<UsersRouter />} />
            <Route path={`${Paths.ROLE.LIST.PATH}/*`} element={<RolesRouter />} />
            <Route path={`${Paths.PERMISSION.LIST.PATH}/*`} element={<PermissionRouter />} />
            <Route path={`settings/*`} element={<SettingsRouter />} />
            <Route path={`hrs/*`} element={<HrsRouter />} />
            <Route path={`finances/*`} element={<FinancesRouter />} />
            <Route path={`warehouses/*`} element={<WareHousesRouter />} />
            <Route path={`partners/*`} element={<PartnersRouter />} />
            <Route path={`customers/*`} element={<CustomersRouter />} />
            <Route path={`logs/*`} element={<LogsRouter />} />
            <Route path={`finance-report/*`} element={<FinanceReportRouter />} />
          </Route>
        </Route>
        <Route path="*" element={<b>Not found</b>}></Route>
      </Routes>
    </HistoryRouter>
  )
}
