import { Meta } from "@/modules/hrs/paysheets/types"

export interface Receipt {
  id?: string
  name: string
  amount: number
  type: string
  notes: string
  date_happened: Date
  category_id: string
  status?: string
  internal_debt_id?: number
}

export enum TypeReceipts {
  EXPENSE = "expense",
  INCOME = "income",
}

export const MAP_TYPE_RECEIPTS = {
  [TypeReceipts.INCOME]: "Thu",
  [TypeReceipts.EXPENSE]: "Chi",
}

export interface DataReceipt {
  items: Receipt[]
  meta: Meta
  statistics?: {
    total_income: number
    total_expense: number
    total_count: number
  }
}
